@font-face {
    font-family: 'Mont'; /* Custom name for the font */
    src: url('/public/fonts/Fontfabric - Mont Regular.otf') format('truetype'); /* Path to font file */
    font-weight: normal; /* Weight for this variation */
    font-style: normal; /* Style for this variation */
}

@font-face {
    font-family: 'Mont'; /* Custom name for the font */
    src: url('/public/fonts/Fontfabric - Mont Bold.otf') format('truetype'); /* Path to font file */
    font-weight: bold; /* Weight for this variation */
    font-style: normal; /* Style for this variation */
}

@font-face {
    font-family: 'Mont'; /* Custom name for the font */
    src: url('/public/fonts/Fontfabric - Mont Regular Italic.otf') format('truetype'); /* Path to font file */
    font-weight: normal; /* Weight for this variation */
    font-style: italic; /* Style for this variation */
}


:root {
    --table-action-color: #337ab7;
    --table-header-bg-color: #ffffff;
    --table-header-text-color: #333;
    --table-row-even-bg-color: #ffffff;
    --table-row-odd-bg-color: #e6e6e6;
    --table-row-text-color: #333;
    --table-footer-bg-color: #ffffff;
    --table-footer-text-color: #333;
    --table-border-color: #666;

    --secondary: #002A46;
    --secondary-text: #fff;

    --header-height: 4.5rem;
}

input[type=radio], input[type=checkbox] {
    accent-color: var(--table-action-color);
}

.fa-color path {
    fill: var(--table-action-color);
    stroke: var(--table-action-color);
}

.fa-color-text path {
    fill: var(--table-row-text-color);
    stroke: var(--table-row-text-color);
}

.fa-secondary-color-text path {
    fill: var(--secondary-text);
    stroke: var(--secondary-text);
}

* {
    font-family: 'Mont';
    box-sizing: border-box;
}

.visx-tooltip-glyph {
    svg {
        width: 1px;
        height: 1px;
    }
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

.app-container {
    width: 100%;
    height: 100%;
    /*background-image: url("/public/jinx.jpg");*/
    /*background-image: url("/public/mavuika.jpg");*/
    /*background-image: url("/public/mc.jpg");*/
    /*background-image: url("/public/mavuika2.jpg");*/
    /*background-image: url("/public/hinata.jpg");*/
    /*background-image: url("/public/idc.jpg");*/
    /*background-image: url("/public/furina.jpg");*/
    /*background-image: url("/public/toph.jpg");*/
    /*background-image: url("/public/albedo.png");*/
    /*background-image: url("/public/ganyu.jpg");*/
    background-size: auto 50vh;
    background-repeat: repeat;
}

body {
    line-height: 1.2;
    font-family: 'Mont';
    min-height: 100vh;
    overflow-y: scroll;
}

main {
    position: relative;
    min-height: 100vh;
    height: 100%;
    background-color: hsl(from var(--table-row-odd-bg-color) h s l / 0.2);
    background-color: var(--table-row-odd-bg-color);
    padding-bottom: .5rem;
    overflow-y: auto;
    flex-grow: 1;
}

.vh-tesdt {
    min-height: 100vh;
    background-color: rgba(69, 106, 61, 0.22);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000;
    height: 100%;
    width: 100%;
}

header {
    margin: 0;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

main {
    padding-left: 1rem;
    padding-right: 1rem;

    @media (max-width: 500px) {
        padding-left: .4rem;
        padding-right: .4rem;

    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
    box-sizing: border-box;
}

.map-colored-value {
    padding: .2rem .2rem;
    line-height: 1.2;
    border-radius: .2rem;
    display: inline-flex;
    white-space: nowrap;
    vertical-align: center;
}

i {
    font-style: italic;
}

hr {
    border: none;
}


input, textarea {
    border: none;
    /*border-radius: .2rem;*/
    border-bottom: 3px solid var(--table-border-color);
    text-indent: .5em;

    &:active {
        outline: none;
        border-bottom-color: var(--table-action-color);
    }

    &:focus-within {
        outline: none;
        border-bottom-color: var(--table-action-color);
    }
}

button {
    cursor: pointer;
}

button:hover {
    filter: brightness(.85);
}

button[disabled] {
    filter: grayscale(.5);
}

.soon {
    height: 3rem;
    padding: 1rem;
    border-radius: 1rem;
    color: var(--table-action-color);
    background: linear-gradient(135deg, rgba(149, 231, 255, 0.87), rgba(255, 255, 255, 1));

    margin-top: .5rem;
    margin-bottom: .5rem;

    font-weight: bolder;

    display: flex;
    justify-content: center;
    align-items: center;
}

.action-btn {
    display: flex;
    width: 100%;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    background: var(--table-action-color);
    color: var(--secondary-text);
    border: none;
    padding: .2rem;
    border-radius: .2rem;
}

.pntr {
    cursor: pointer;
}

.text-align-center {
    text-align: center;
}

*::-webkit-scrollbar {
    width: 1rem;
}

*::-webkit-scrollbar-track {
    background: var(--table-footer-bg-color);
}


*::-webkit-scrollbar-thumb {
    background-color: var(--table-action-color);
}

* {
    scrollbar-width: thin;
    scrollbar-color: var(--table-action-color) var(--table-footer-bg-color);
}

.not-found-table-cell {
    /*width: 100%;*/
    vertical-align: middle;

}
.not-found-table-row {
    height: 10rem;
}
