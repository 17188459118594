
.glyph-input {
    width: 3em;
    text-align: center;
    text-indent: 0;
    margin-left: .2rem;
    border-radius: .2rem;
    border-bottom: none;
}

.chart-height-input {
    width: 5em;
    margin-left: .2rem;
    border-radius: .2rem;
    border-bottom: none;
}

.chart-axis-name-input {
    width: 12em;
    border-radius: .2rem;
    border-bottom: none;
}

.xy-settings label {
    cursor: pointer;
}

.xy-settings input[type=radio] {
    /*scale: 1.5;*/
    margin: 0 .2rem 0 0;
    height: 1rem;
    width: 1rem;
    vertical-align: middle;
    cursor: pointer;
    color: var(--table-row-text-color);
}

.xy-settings input[type=checkbox] {
    cursor: pointer;
    margin: 0;
    height: 1rem;
    width: 1rem;
    vertical-align: middle;
}

.lh-12 {
    line-height: 1.2;
}

.chart-name {
    line-height: 1;
    font-size: 1.2rem;
}

.chart-controls {
    display: flex;
    gap: .5rem;
}

.chart-head-controls-button {
    background: var(--table-row-odd-bg-color);
    padding: .2rem;
    cursor: pointer;
    border-radius: .2rem;
    display: flex;
    align-items: center;
    border: none;

    &:hover {
        filter: brightness(.85);
    }
}

.chart-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 2rem;
}
