
#side-head-content {
    overflow-y: auto;
    flex-grow: 1;
}

.aside-header {
    position: fixed;
    background-color: hsl(from var(--secondary) h s l / 0.6);
    background-color: var(--secondary);
    height: 100vh;
    height: 100%;
    /*max-height: 100vh;*/
    display: flex;
    flex-flow: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
    z-index: 1;
    color: var(--secondary-text);
}

.aside-header .close-btn {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    color: var(--table-action-color);
    background: none;
    border: none;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    justify-content: center;
}

.aside-head-info {
    display: flex;
    flex-flow: column;
    gap: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;

}

.aside-head-info .info .project-time {
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;
    align-items: center;
}

.aside-head-info .info .dot {
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    background-color: #6ac93d;
}

.aside-head-info .info {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-flow: column;
    gap: .5rem;
}

.aside-head-info .info h2 {
    font-size: 2rem;
    line-height: 1;
}


.aside-head-info .logo img {
    width: 75px;
    height: 75px;
}

.aside-head-info .name {
    display: flex;
    flex-flow: column;
    gap: .5rem;
}
.aside-head-info .logo {
    display: flex;
    gap: .5rem;
    height: 100%;
}

.aside-head-info .name-dots .dots {
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    padding: 0;

    &:hover {
        filter: brightness(.85);
    }

}

.aside-head-info .name-dots {
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.head-actions {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 12px;
    gap: 1.5rem;
}

.head-actions .list {
    display: flex;
    flex-flow: column;
    gap: 1rem;
}

.head-actions .header-action-btn {
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    padding: 0;
    color: var(--secondary-text);
    font-size: 1rem;
    width: 100%;

    &:hover {
        filter: brightness(.85);
    }
}

.head-actions .dot {
    margin-right: 14px;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    background-color: var(--table-action-color);

}


.head-actions .logout {
    display: flex;
    gap: .5rem;
    align-items: center;

    background: transparent;
    cursor: pointer;
    border: none;
    padding: 0;
    color: var(--secondary-text);
    font-size: 1rem;
    width: 100%;

    &:hover {
        filter: brightness(.85);
    }

}

.aside-table-func .table-name {
    width: 100%;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    margin-bottom: .5rem;

}

.aside-table-func .table-name .count {
    background: var(--table-action-color);
    color: var(--secondary-text);
    padding: .1rem .2rem;
    border-radius: .2rem;
    font-size: 15px;
    vertical-align: top;
}

.aside-table-func {
    display: flex;
    flex-flow: column;
    gap: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    opacity: 0;
    transition: opacity .4s ease;
}

.bottom-nav .nav-item button{
    background: none;
    border: none;
    color: var(--secondary-text);

    display: flex;
    flex-flow: column;
    align-items: center;
    gap: .2rem;
}

.bottom-nav .nav-item .btn-name{
    font-size: 12px;
}
.bottom-nav .nav-item {


}

.bottom-nav > ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--secondary);
    color: var(--secondary-text);
}

.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    background-color: var(--secondary);
    color: var(--secondary-text);
}
