
:root {


}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}


.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.yellow-exla {
    background-color: yellow;
    border-radius: .2rem;
    padding: 0 .2rem;
    font-size: 14px;
}

body {
    --toastify-color-light: var(--table-row-odd-bg-color);
    --toastify-color-dark: var(--table-row-odd-bg-color);
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-toast-width: 320px;
    --toastify-toast-background: var(--table-row-odd-bg-color);
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;

    --toastify-text-color-light: var(--table-row-text-color);
    --toastify-text-color-dark: var(--table-row-text-color);

    --toastify-text-color-info: var(--table-row-text-color);
    --toastify-text-color-success: var(--table-row-text-color);
    --toastify-text-color-warning: var(--table-row-text-color);
    --toastify-text-color-error: var(--table-row-text-color);

    --toastify-spinner-color: var(--table-action-color);
    --toastify-spinner-color-empty-area: var(--table-action-color);
}


.flex {
    display: flex;
}

.fl-col {
    flex-flow: column;
}

.fl-ju-ce {
    justify-content: center;
}

.fl-ju-sb {
    justify-content: space-between;
}

.fl-al-ce {
    align-items: center;
}

.fl-wrap {
    flex-wrap: wrap;
}

.gap-5 {
    gap: .5rem;
}

.gap-2 {
    gap: .2rem;
}

.ml-2 {
    margin-left: .2rem;
}

.ml-4 {
    margin-left: .4rem;
}

.mr-2 {
    margin-right: .2rem;
}

.mt-4 {
    margin-top: .4rem;
}



