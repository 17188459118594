
.aside-dashboard-container {
    display: flex;
    flex-flow: column;
    gap: .5rem;
    height: 100%;
    margin-bottom: .5rem;
    overflow-y: auto;

    opacity: 0;
    transition: opacity .4s ease;
}

.interval-selection-container .update-btn{
    background: var(--table-action-color);
    color: var(--secondary-text);
    border: none;
    padding: .4rem;
    display: flex;
    gap: .5rem;
    justify-content: center;
    align-items: center;
    border-radius: .4rem;
}

.interval-selection-container {
    display: flex;
    flex-flow: column;
    gap: .5rem;
}

.interval-selection-row-double {
    display: flex;
    flex-flow: column;
}

.interval-selection-row .period-name {
    border: none;
    padding: .2rem .4rem;
    flex-grow: 0;
    background: var(--table-row-odd-bg-color);

    font-size: 14px;
    color: var(--table-row-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

/*.interval-selection-row .period:last-child {*/
/*    border-bottom-right-radius: .4rem;*/
/*    border-top-right-radius: .4rem;*/
/*}*/

.one-line .period-name {
    border-bottom-left-radius: .4rem;
    border-top-left-radius: .4rem;
}

.two-line .period-name {
    border-top-left-radius: .4rem;
}

.two-line.first .period:last-child {
    border-top-right-radius: .4rem;
}

.one-line.first .period:last-child {
    border-bottom-right-radius: .4rem;
    border-top-right-radius: .4rem;
}

.second .period:last-child {
    border-bottom-right-radius: .4rem;
}

.second .period:first-child {
    border-bottom-left-radius: .4rem;
}

.br-tr {
    border-top-right-radius: .4rem;
}

.br-tl {
    border-top-left-radius: .4rem;
}

.br-bl {
    border-bottom-left-radius: .4rem;
}

.br-br {
    border-bottom-right-radius: .4rem;
}


.interval-selection-row .period {
    flex-grow: 1;
    font-size: 13px;
}

.interval-selection-row {
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.xy-side-container .name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .5rem;
    background: transparent;
    color: var(--secondary-text);
    border: none;
    border-radius: .5rem;
    font-size: 14px;
    padding-top: .5rem;

}

.xy-side-container {
    display: flex;
    flex-flow: column;
    margin-right: .5rem;
    margin-left: .5rem;
    gap: .5rem;
    padding-bottom: .5rem;
    border-bottom: 3px solid var(--table-action-color);
    /*background: var(--table-row-odd-bg-color);*/
    /*border-radius: 5px;*/
}

.chart-period .val {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.chart-period-container .date-picker .date-btn{
    background: none;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0;
    width: 1rem;
    height: 1rem;
}
.chart-period-container .date-picker{
    flex-shrink: 0;
    margin-left: auto;
    padding-right: .2rem;

    & input {
        width: 0;
        min-width: 0;
        min-height: 0;
        height: 0;
        visibility: hidden;
    }
}
.chart-period-container {
    display: flex;
    gap: 1rem;
    justify-content: space-evenly;
    background: var(--table-row-odd-bg-color);
    color: var(--table-row-text-color);
    padding: 5px;
    border-radius: 10px;
    align-items: center;
    line-height: 1;
    max-width: 400px;
    width: 100%;
}
.chart-period {
    flex-grow: 1;
    display: flex;
    gap: 1rem;
    justify-content: space-evenly;
    align-items: center;
    line-height: 1;
}

.dashboard-controls-container .cntrl {
    padding: .4rem;
    border: none;
    background: var(--table-row-odd-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-controls-container {
    display: flex;
    gap: .5rem;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: .5rem;
    padding-left: .5rem;
    padding-right: .5rem;
}
